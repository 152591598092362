import React, {useEffect, useState} from 'react'
import { RetriveTest,DeleteTest,Get_Categorry,Get_AgeGroup,Get_SubCategory } from '../Services/TestServices';
import TestRegistration from '../Pages/TestRegistration';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Tooltip from '@mui/material/Tooltip';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import {  
    Table,
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    TablePagination,
    Pagination,
    Divider,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
  } from '@mui/material';
  import Stack from '@mui/material/Stack';
  import Slide from '@mui/material/Slide';
  import SpinnerModal from '../Spinner/SpinnerModal';
  import { useApi } from '../ContextApi/ContextApi';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import  { tableCellClasses } from '@mui/material/TableCell';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TestSettings from './TestSettings';
import '../UI/TestEntry.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2c6bd7',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: '#ffffff',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const Transition = React.forwardRef(function Transition(props, ref) {
    const transitionDuration = 500; // milliseconds
    return <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />;
  });
  
const TestEntry = () =>{
    const { isTest_Submitted,setTest_SubmissionStatus } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [TesData,setTestData] = useState([]);
    const [selectedTestId, setSelectedTestId] = useState(null);
    const [DeletedTestId, setDeletedTestId] = useState(null);
    const [isDelete,SetisDelete] = useState(false);
    const[CategorryData,SetCateGorryData] = useState([]);
    const[AgegroupData,SetAgegroupData] = useState([]);
    const[SubCategory,SetSubCategory] = useState([]);
    const[UserRole,SetUserRole] = useState(null);
    const [Params,setParams] = useState({
        CATEGORY_CODE:'',
        GenderCategory:'',
        AGE_CODE:'',
        LabID:sessionStorage.getItem('LabID'),
        SUB_CATEGORY_CODE:'',
    })

//-------------------retrive All test including filters----------------
     const RetriveTestData = async ()=>{
        setIsLoading(true);
        try{
            const Response = await RetriveTest(Params);
            setTestData(Response)
            setTest_SubmissionStatus(false)
        }
        catch(error){
            console.log(error)
        }
        finally{
            setIsLoading(false)
        }
     }
    useEffect(() => {
        RetriveTestData();
    },[isTest_Submitted])
    
//get Test ID
const handleEditTest = (testId) => {
    setSelectedTestId(testId);
}
//Delet Test
const handleDeleteTest = (testId) =>{
    
    setDeletedTestId(testId)
    if(testId){
        SetisDelete(true)
    }else{
        SetisDelete(false)
    }
}
const HandleDelete = () => {
    if(DeletedTestId){
        setIsLoading(true)
        const LabID = sessionStorage.getItem('LabID')
        const response = DeleteTest(DeletedTestId,LabID)
        if(response){
           setIsLoading(false);
           setTest_SubmissionStatus(true);
           toast.success("Data Deleted Successfully");
        }
    }
    else{
        toast.error("Test Id are missing")
    }
    SetisDelete(false);
  };
  //Handle Close Delete Modal
  const handleClose = () =>{
    SetisDelete(false)
  }
//-------------------Pagination
const itemsPerPage = 10; // Number of items per page
const [currentPage, setCurrentPage] = useState(1); // Pages start from 1

// Calculate the range of items to display on the current page
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const limitedData = TesData.slice(indexOfFirstItem, indexOfLastItem);

// Handle page change
const handlePageChange = (event, newPage) => {
  setCurrentPage(newPage);
};
//-------------------------------

  const Gender = [
    {
      value: '0',
      label: 'All',
    },
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
   
  ];
 
//-------------------
const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

const onChange = (e) => {
    const { name, value } = e.target;
    setParams({
      ...Params,
      [name]: value,
    });
  };
  //---------\
  const GetSettingData = async () => {
    try {
        const response = await Get_Categorry(sessionStorage.getItem('LabID'));
        SetCateGorryData(response);
    } catch (error) {
        console.error("Error fetching settings:", error);
    }
};
const GetAgeGroup = async () => {
  try {
      const response = await Get_AgeGroup(sessionStorage.getItem('LabID'));
      SetAgegroupData(response);
  } catch (error) {
      console.error("Error fetching settings:", error);
  }
};

const GetSubCategory = async () => {
  try {
      const response = await Get_SubCategory(sessionStorage.getItem('LabID'));
      SetSubCategory(response);
  } catch (error) {
      console.error("Error fetching settings:", error);
  }
};

  useEffect(()=>{
    GetSettingData();
    GetAgeGroup();
    GetSubCategory();
    const User_Role = sessionStorage.getItem('User_Role');
    SetUserRole(User_Role)
 },[])

 //---------------Formate normal range and unit value -- function 
 const formatTextWithLineBreaks = (text) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

//---------------END ----------------
    //================Search Test =============
const handleChange = (e) => {
  const value = e.target.value.trim();  
  if(value == null || value == ""){
    RetriveTestData();
  }else{
    const filtered = TesData.filter((patient) => {
      const testtName = patient?.testName?.toLowerCase()?.trim();
      const searchValue = value.toLowerCase();
      return  testtName?.includes(searchValue) ; 
    });
    setTestData(filtered);
  }
  }
//===================END==================

//-----------Data table implementation--------------------
const columns = [
  {
    name: "Category",
    selector: (row) => row.categorY_VALUE,
    cellExport: (row) => row.categorY_VALUE,
    sortable: true,
  },
  {
    name: "Sub Category",
    selector: (row) => row.suB_CATEGORY_VALUE,
    cellExport: (row) => row.suB_CATEGORY_VALUE,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => row.testName,
    cellExport: (row) => row.testName,
    sortable: true,

  },
  {
    name: "Reference Value",
    selector: (row) => formatTextWithLineBreaks(row.normalRange),
    cellExport: (row) => formatTextWithLineBreaks(row.normalRange),
    sortable: true,
  },
  {
    name: "Price",
    selector: (row) => `${row.testPrice}-/pkr`,
    cellExport: (row) => `${row.testPrice}-/pkr`,
    sortable: true,
  },
  {
    name: "Unit",
    selector: (row) => formatTextWithLineBreaks(row.unit),
    cellExport: (row) => formatTextWithLineBreaks(row.unit),
    sortable: true,
  },
  {
    name: "Gender",
    selector: (row) => (row.genderCategory !== "0" ? row.genderCategory : "All"),
    cellExport: (row) => (row.genderCategory !== "0" ? row.genderCategory : "All"),
    sortable: true,
  },
  {
    name: "Age Group",
    selector: (row) => (row.agE_VALUE !== "" && row.agE_VALUE !== "0" ? row.agE_VALUE : "All"),
    cellExport: (row) =>(row.agE_VALUE !== "" && row.agE_VALUE !== "0" ? row.agE_VALUE : "All"),
    sortable: true,
  },
  {
    name: "Actions",
    button: true, // Custom cell for action buttons
    cell: (row) => (
      <div>
        {UserRole === "Admin" && (
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <Tooltip title="Delete Test" placement="left"> */}
              <button
                type="button"
                className="Action_Button"
                onClick={() => handleDeleteTest(row.testID)}
              >
                <DeleteForeverRoundedIcon sx={{ fontSize: "18px", color: "red" }} />
              </button>
            {/* </Tooltip> */}
            {/* <Tooltip title="Edit Test" placement="left"> */}
              <button
                type="button"
                className="Action_Button"
                onClick={() => handleEditTest(row.testID)}
              >
                <EditRoundedIcon sx={{ fontSize: "18px", color: "blue" }} />
              </button>
            {/* </Tooltip> */}
          </div>
        )}
      </div>
    ),
  },
];
const data = TesData;
const tableData = {
  columns,
  data: data,
};
const customStyles = {
  rows: {
    style: {
      borderBottom: '1px solid #ccc', // Row border
    },
  },
  cells: {
    style: {
      // borderRight: '1px solid #ccc', // Column border
      fontSize:'12px'
    },
  },
  headCells: {
    style: {
      borderTop:'1px solid #ccc',
      // borderRight: '1px solid #ccc', // Column border in header
      backgroundColor: 'white',
    },
  },
};
    return (
        <>
        {isLoading && <SpinnerModal  isLoading={isLoading}/>}
        <div className='container-fluid Container2'>
         <Paper className='common-box-shadow common-bg-white common-br-3px' sx={{ width: '100%', overflow: 'hidden' ,border:'1px solid lightgray',boxShadow:'none'}}>
          <div style={{padding:'0px 15px'}} className='d-flex justify-content-between  mt-3'>
              <h5 className='common-FontWeight common-FontFamily'>Register Test</h5>
            <button className='common-setting-button' onClick={openModal}><SettingsRoundedIcon />Settings</button>
          </div>
          <hr></hr>
             <Box>
                <TestRegistration  TestID={selectedTestId}/>
            </Box>
            <Divider className='mt-3'></Divider>
            <div className='row p-2'> 
                    <div className='col-sm-2'>
                      <label className='common-Labels' >Main Catagory</label>
                      <select
                        className="form-select  common-Input"
                        id='CATEGORY_CODE'
                        name='CATEGORY_CODE'
                        required
                        onChange={onChange}
                        value={Params.CATEGORY_CODE}
                        >
                        <option value='0'>All</option>
                        {CategorryData.map((option) => (
                            <option key={option.categorY_CODE} value={option.categorY_CODE}>
                            {option.categorY_VALUE}
                            </option>
                            ))}
                    </select>
                  </div>
                  <div className='col-sm-2'>
                      <label className='common-Labels' >Sub Catagory</label>
                      <select
                        className="form-select  common-Input"
                        id='SUB_CATEGORY_CODE'
                        name='SUB_CATEGORY_CODE'
                        required
                        onChange={onChange}
                        value={Params.SUB_CATEGORY_CODE}
                        >
                        <option value='0'>All</option>
                        {SubCategory.map((option) => (
                            <option key={option.suB_CATEGORY_CODE} value={option.suB_CATEGORY_CODE}>
                            {option.suB_CATEGORY_VALUE}
                            </option>
                            ))}
                    </select>
                  </div>
                  <div className='col-sm-2'>
                    <label className='common-Labels' >Geneder Groups</label>
                    <select
                        className="form-select  common-Input"
                        id='GenderCategory'
                        name='GenderCategory'
                        required
                        onChange={onChange}
                        value={Params.GenderCategory}
                        >
                        {Gender.map((op) => (
                            <option key={op.value} value={op.value}>
                            {op.label}
                            </option>
                        ))}
                     </select>
                    </div>
                    <div className='col-sm-2'>
                     <label className='common-Labels' >Age Groups</label>
                      <select className="form-select  common-Input"
                            id='AGE_CODE'
                            name='AGE_CODE'
                            required
                            onChange={onChange}
                            value={Params.AGE_CODE}
                            >
                            <option value='0'>All</option>  
                           {AgegroupData.map((option) => (
                            <option key={option.agE_CODE} value={option.agE_CODE}>
                            {option.agE_VALUE}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-sm-3 mt-4'>
                      <button onClick={RetriveTestData}  className="common-button">Apply</button>
                    </div>
            </div>  
          <DataTableExtensions  
              columns={tableData.columns}
              data={tableData.data} 
              export={true} 
              print={true}   
              >
          <DataTable
            pagination
            fixedHeader
            fixedHeaderScrollHeight="80vh"
            highlightOnHover
            pointerOnHover
            responsive
            striped
            customStyles={customStyles}
          />
        </DataTableExtensions>
   </Paper>
    <div>
      <div>
        <Dialog PaperProps={{ style: { borderRadius:'3px',padding:'10px'},}}    open={isDelete} keepMounted onClose={handleClose}   TransitionComponent={Transition}>
          <div style={{textAlign:'end'}}>
            <button className='common-cross-button' onClick={handleClose}>
              <CloseRoundedIcon />
            </button>
          </div>
                <DialogTitle style={{textAlign:'center'}} className='common-FontFamily'>
                  <CancelOutlinedIcon style={{fontSize:'100px',color:'red'}}/><br></br>
                  <h4>Are you sure!</h4>
                </DialogTitle>
            <DialogContent> 
                <DialogContentText id="alert-dialog-slide-description">
                    <span>  Do you really want to delete these record?</span><br></br>
                    <span> This proccess cannot be undone.</span> 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <button className='btn dialog-Cancel-button' onClick={handleClose}>Cancel</button>
            <button className='btn dialog-Delete-button' onClick={HandleDelete}>Delete</button>
            </DialogActions>
        </Dialog>
    </div>    
  </div>
</div>
              <Dialog  PaperProps={{ style: { borderRadius:'3px'  }, }} className='common-modal'  open={modalIsOpen} keepMounted onClose={closeModal}  TransitionComponent={Transition}>
                    <DialogTitle className='common-FontFamily d-flex justify-content-between' sx={{fontWeight:600,backgroundColor:'#2c6bd7',color:'white'}}>
                        {"Test settings"}
                        <button style={{textAlign:'end',color:'white'}} className='common-cross-button' onClick={closeModal}>
                         <CloseRoundedIcon />
                        </button>
                     
                      </DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                        <TestSettings />
                    </DialogContent>
                </Dialog>
 </>
    
    )
}

export default TestEntry