import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import moment from 'moment/moment';
import QRCode from 'react-qr-code';  
import '../UI/PrintReport.css';

const Template2 = ({ reportData ,DRS,FontStyle,QRurl}) => {
  const currentDateTime = new Date();
  const formattedCurrentDate = moment(currentDateTime).format('D MMM YYYY');
  const username = sessionStorage.getItem("FirstName") + sessionStorage.getItem("LastName");

  const groupByCategoryAndSubCategory = (data) => {
    return data.reduce((result, item) => {
      const category = item.categorY_CODE;
      const subCategory = item.suB_CATEGORY_CODE;

      if (!result[category]) {
        result[category] = {
          categoryValue: item.categorY_VALUE,
          subCategories: {},
        };
      }

      if (!result[category].subCategories[subCategory]) {
        result[category].subCategories[subCategory] = {
          subCategoryValue: item.suB_CATEGORY_VALUE,
          items: [],
        };
      }

      result[category].subCategories[subCategory].items.push(item);
      return result;
    }, {});
  };
  const groupedData = groupByCategoryAndSubCategory(reportData);

  // Function to format normal range and unit values
  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const LabName = reportData.find((x) => x.laboratoryName != '' && x.laboratoryName != null)?.laboratoryName;
  const qrCodeValue = QRurl ;
  return (
    <>
    <div className='print-header'>
          <div style={{ width: '100%'}}>
            <img className="logo" src={"data:image/png;base64," + reportData?.[0]?.data} alt="Lab Logo" style={{ width: '100%', height: '130px', objectFit: 'contain' }} />
          </div>
          <div className='print-Patient-Details' style={{  fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily : 'Georgia serif',padding:'10px',fontSize:FontStyle[0]?.fontsize? FontStyle[0]?.fontsize : '10px' ,fontweight:'bold'}}>
          <Grid container style={{marginTop:'20%'}}>
            <Grid item xs={4}>
              <div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>MR No:</span>&nbsp;<span>{reportData?.[0]?.patientID}-{sessionStorage.getItem('LabID')}</span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Patient Name:</span>&nbsp;<span>{reportData?.[0]?.patientName}</span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {reportData?.[0]?.sex === 'male' ? 'Father Name :' : 'Father / Husband :'}
                  </span> &nbsp; <span></span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Age|Gender:</span>&nbsp;<span>{reportData?.[0]?.age} Years | {reportData?.[0]?.sex}</span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Address:</span>&nbsp;<span>{reportData?.[0]?.address}</span>
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Phone Number:</span>&nbsp;<span>{reportData?.[0]?.contactNumber}</span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Referred by:</span>&nbsp;<span>{reportData?.[0]?.referredBy}</span>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>Registration Date&Time:</span>&nbsp;<span>{moment(reportData?.[0]?.createdAt).format('D MMM YYYY')}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column',marginLeft:'10px' }}>
              <QRCode value={qrCodeValue} size={100} />
              <span className='common-FontSize-small'>Scan to Download</span>
            </Grid>
            </Grid>
          </div>
          <hr style={{ marginTop: 0}} />
    </div> 
      <div className='print-content'>
        {Object.keys(groupedData).map((category, index) => {
          let subCategoryKeys = Object.keys(groupedData[category].subCategories);

          if (groupedData[category].categoryValue.toUpperCase() === "URINE EXAMINATION REPORT") {
            const order = ["PHYSICAL EXAMINATION", "CHEMICAL EXAMINATION", "MICROSCOPIC EXAMINATION"];
            subCategoryKeys.sort((a, b) => {
              const subCategoryA = groupedData[category].subCategories[a].subCategoryValue.toUpperCase();
              const subCategoryB = groupedData[category].subCategories[b].subCategoryValue.toUpperCase();
              const indexA = order.indexOf(subCategoryA);
              const indexB = order.indexOf(subCategoryB);
              return (indexA === -1 ? order.length : indexA) - (indexB === -1 ? order.length : indexB);
            });
          }

          return (
            <div  key={index} style={{  fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily : 'Georgia serif',padding:'10px',fontSize:FontStyle[0]?.fontsize? FontStyle[0]?.fontsize : '10px' ,fontweight:'bold'}}>
              <h4 className='common-FontWeight' style={{ textAlign: 'center' }}>{groupedData[category].categoryValue}</h4>
              {subCategoryKeys.map((subCategory, subIndex) => (
                <div key={subIndex}>
                  <span className='common-FontWeight'>{groupedData[category].subCategories[subCategory].subCategoryValue}</span>
                  <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <thead style={{ backgroundColor: '#f8f8ff' }}>
                      <tr>
                        <th style={{ width: '40%' }}>Test Description</th>
                        <th style={{ width: '20%' }}>Normal Range</th>
                        <th style={{ width: '20%' }}>Unit</th>
                        <th style={{ width: '20%' }}>Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData[category].subCategories[subCategory].items.map((data, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{data.testName}</td>
                            <td>{formatTextWithLineBreaks(data.normalRange)}</td>
                            <td>{formatTextWithLineBreaks(data.unit)}</td>
                            <td style={{ fontWeight: 'bold' }}>{data.testResult}</td>
                          </tr>
                          {data.comment && (
                            <tr>
                              <td colSpan={4} style={{ paddingLeft: '20px', fontStyle: 'italic' }}>
                                Comment: {data.comment}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <div className ='print-footer' style={{alignItems:'center',fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily :  'Georgia serif' }}>
          <div>
            <hr style={{ marginTop: 0,marginBottom:0}} />
          </div>
          <div className='d-flex justify-content-between'>
              <span className='col-6'>
                  <span>Printed at: {formattedCurrentDate}</span> &nbsp; <span>Printed By: {username}</span>
              </span>
          </div>
        <table style={{ width: '100%', tableLayout: 'auto' }}>
            <tbody>
              <tr>
                {DRS.map((data, index) => (
                  <td key={index} style={{ padding: '10px', verticalAlign: 'top', wordWrap: 'break-word', fontFamily:FontStyle[0]?.fontfamily? FontStyle[0]?.fontfamily :  'Georgia serif',fontSize:FontStyle[0]?.fontsize? FontStyle[0]?.fontsize : '12px' }}>
                    <div style={{fontweight:'bold'}}>{data.drname}</div>
                    <div>{data.designation}</div>
                    <div>{data.description}</div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div style={{textAlign:'center'}}>
            <p>&copy; {new Date().getFullYear()} {LabName}. All rights reserved.</p>
          </div>
          
      </div>
      
    </>
  );
};

export default Template2;
