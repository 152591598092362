import React, { useEffect, useState,useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import {GloabalSearchPatient } from '../Services/PatientServicces';
import { GetDashboard } from '../Services/Dashboard';
import Tooltip from '@mui/material/Tooltip';
import SpinnerModal from '../Spinner/SpinnerModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../UI/Layout.css';
// import image from '../assets/SS.png'
import image from '../assets/SLW2.png'

import useradd from '../assets/user-add.svg'
import multiUser from '../assets/multiuser.svg'
import settings from '../assets/settings.svg'
import pending from '../assets/pending.svg'
import Dashboard from '../assets/Dashboard.svg'
import report from '../assets/chart-simple.svg'
import userlock from '../assets/user-lock.svg'
import tests from '../assets/tests.svg'
import template from '../assets/template-alt.svg'
import logout from '../assets/sign-out-alt.svg'

const drawerWidth = '50px';
const bgcolor = '#2c6bd7';
const color = '#ffff'

const AppBar = styled('div')(({ theme }) => ({
  //...theme.mixins.toolbar,
  backgroundColor: '#ffffff',
  boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dashboardData, setDashboardData] = useState([]);
  const [User_Role, SetUser_Role] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [FirstN, setFirstName] = useState('');
  const [LastN, setLastName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorsetting, setanchorsetting] = useState(null);

  const [PatientData,setPatientData] = useState([]);
  const [inputdata, setInputData] = useState('');
  const tblRef = useRef(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const settingMenuOpen = (event) => setanchorsetting(event.currentTarget);
  const settingMenuClose = () => setanchorsetting(null);
  const HandleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const GetLabDetails = async () => {
    const LabID = sessionStorage.getItem('LabID');
    const response = await GetDashboard(LabID);
    setDashboardData(response);
  };

  useEffect(() => {
    const User_Role = sessionStorage.getItem('User_Role');
    SetUser_Role(User_Role);
    GetLabDetails();
    const FirstName = sessionStorage.getItem('FirstName');
    const LastName = sessionStorage.getItem('LastName');
    setFirstName(FirstName?.charAt(0));
    setLastName(LastName?.charAt(0));
  }, []);

  const isActive = (path) => location.pathname === path;

  //================Search patient =============
const handleChange = (e) => {
  const value = e.target.value;
  setInputData(value);
};

const hnadleSearchPatient = async () => {
  setIsLoading(true);
  try {
        if(inputdata != null || inputdata != ""){
          const LabID = sessionStorage.getItem('LabID');
          const result = await GloabalSearchPatient(LabID, inputdata);
              setPatientData(result);
        }
          
      } catch (error) {
        console.log('error while searching patient', error);
      } finally {
        setIsLoading(false);
      }
};
//===================END==================
////--------------open profile------------

const NavigatetoProfile = (id) =>{
  window.location.href = `/Home/PatientProfile/${id}`;
  setPatientData("");
}
//------------end---------------
  //-------------------Detect clicks outside of the <ul>----------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tblRef.current && !tblRef.current.contains(event.target)) {
        setPatientData(""); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside); // Listen for mouse click

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //---------------------END----------------------
  //-----------Long lab name------------
  const getLimitedText = (text, wordLimit) => {
    if (!text) return ''; 
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };
  //---------------------END-----------
  return (
    <>
      {isLoading && <SpinnerModal isLoading={isLoading} />}
      <Box sx={{ display: 'flex',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            backgroundColor:'#2c6bd7',
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box',backgroundColor:bgcolor,color:color,overflow:'hidden'},
          }}
        >
         <Toolbar>
           <img className='logo'  src={image}></img>
         </Toolbar>
          <List>
            {User_Role === 'Admin' && (
              <ListItem onClick={() => navigate('/Home/Dashboard')} disablePadding>
                 <Tooltip title="Dashboard" placement="right">
                  <ListItemButton selected={isActive('/Home/Dashboard')}sx={{height:'50px', paddingLeft:'10px',paddingRight:'10px',  alignItems: 'center',borderLeft: isActive('/Home/Dashboard') ? '4px solid white' : 'none', }} >
                    <ListItemIcon  sx={{ minWidth: '0px' }}>
                      <img src={Dashboard} alt="user" width="24" height="24" style={{filter: 'invert(1)'}} />
                      </ListItemIcon>
                  </ListItemButton>
                 </Tooltip>
                
              </ListItem>
            )}
             <ListItem onClick={() => navigate('/Home/PendingTest')} disablePadding>
             <Tooltip title="Pending Orders" placement="right">
             <ListItemButton sx={{height:'50px', paddingLeft:'10px',paddingRight:'10px',  alignItems: 'center',borderLeft: isActive('/Home/PendingTest') ? '4px solid white' : 'none', }} selected={isActive('/Home/PendingTest')}>
                <ListItemIcon  sx={{ minWidth: '0px' }}>
                 <img src={pending} alt="user" width="24" height="24" style={{filter: 'invert(1)'}} />
                  </ListItemIcon>
              </ListItemButton>
             </Tooltip>
              
            </ListItem>
            <ListItem onClick={() => navigate('/Home/PatientRegistration')} disablePadding>
            <Tooltip title="Patient Registration" placement="right">
             <ListItemButton  selected={isActive('/Home/PatientRegistration')} sx={{height:'50px', paddingLeft:'10px',paddingRight:'10px',   alignItems: 'center',borderLeft: isActive('/Home/PatientRegistration') ? '4px solid white' : 'none', }} >
                <ListItemIcon sx={{ minWidth: '0px' }}>
                      <img src={useradd} alt="user" width="24" height="24" style={{filter: 'invert(1)'}} />
                  </ListItemIcon>
              </ListItemButton>
            </Tooltip>
             
            </ListItem>
            <ListItem onClick={() => navigate('/Home/PatientList')} disablePadding>
              <Tooltip title="Patients" placement="right">
              <ListItemButton selected={isActive('/Home/PatientList')} sx={{ height:'50px', paddingLeft:'10px',paddingRight:'10px',    alignItems: 'center',borderLeft: isActive('/Home/PatientList') ? '4px solid white' : 'none', }}>
                  <ListItemIcon sx={{ minWidth: '0px' }}>
                    <img src={multiUser} alt="user" width="24" height="24" style={{filter: 'invert(1)'}} />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
            {User_Role === 'Admin' && (
            <ListItem onClick={() => navigate('/Home/Reports')} disablePadding>
              <Tooltip title="Reports" placement="right">
                <ListItemButton sx={{height:'50px', paddingLeft:'10px',paddingRight:'10px',   alignItems: 'center',borderLeft: isActive('/Home/Reports') ? '4px solid white' : 'none', }} selected={isActive('/Home/Reports')}>
                    <ListItemIcon  sx={{ minWidth: '0px' }}>
                      <img src={report} alt="user" width="24" height="24" style={{filter: 'invert(1)'}} />
                      </ListItemIcon>
                  </ListItemButton>
              </Tooltip>
            </ListItem>
            )}
            {User_Role === 'Admin' && (
           <ListItem onClick={settingMenuOpen} disablePadding >
            <Tooltip title="Settings" placement="right">
              <ListItemButton  sx={{ height:'50px', paddingLeft:'10px',paddingRight:'10px', alignItems: 'center'}}>
                  <ListItemIcon sx={{ minWidth: '0px' }} >
                    <img src={settings} alt="user" width="24" height="24" style={{filter: 'invert(1)'}} />
                    </ListItemIcon>
                </ListItemButton>
            </Tooltip>
            </ListItem>
            )}
            <Menu  className='menu' anchorEl={anchorsetting} open={Boolean(anchorsetting)} onClose={settingMenuClose}>
                <Tooltip title="Tests" placement="right">
                  <MenuItem sx={{backgroundColor:'#2c6bd7'}}  onClick={() => {navigate('/Home/TestEntry');settingMenuClose();}}>
                  <img src={tests} alt="tests" width="24" height="24" style={{filter: 'invert(1)'}} />
                  </MenuItem>
                </Tooltip>
                <Tooltip  title="Template setup" placement="right">
                <MenuItem sx={{backgroundColor:'#2c6bd7'}}  onClick={() =>{ navigate('/Home/TemplateSetting');settingMenuClose();}}>
                    <img src={template} alt="template" width="24" height="24" style={{filter: 'invert(1)'}} />
                  </MenuItem>
                </Tooltip>
                    <Tooltip title="Account setup" placement="right">
                        <MenuItem sx={{backgroundColor:'#2c6bd7'}}  onClick={() =>{ navigate('/Home/User-Management');settingMenuClose();}} >
                          <img src={userlock} alt="userlock" width="24" height="24" style={{filter: 'invert(1)'}} />
                        </MenuItem>
                      </Tooltip>
                 
            </Menu>
            
          </List>
        </Drawer>

        {/* Main Content */}
        <Box className='Horizental-bar' sx={{ flexGrow: 1, position:'fixed',width:'100%' ,zIndex:'999',textAlign:'center'}}>
          <AppBar>
            <div className='lab-name' style={{minWidth:'6rem'}}>
              <h5 className='common-FontWeight common-FontFamily'> 
                  {getLimitedText(dashboardData?.labDetails?.laboratoryName, 2)}
                 
              </h5> 
            </div>
            {/* <div className='parent-inputsearch'> */}
              <div className='InputSearch'>
                <button
                  className="searchButton"
                  onClick={hnadleSearchPatient}
                >
                  <SearchOutlinedIcon />
                </button>
                <input
                  style={{ border: 'none', outline: 'none', fontSize: 'smaller', width: '80%',marginLeft:'15px' }}
                  onKeyDown={(e) => { if (e.key === 'Enter') hnadleSearchPatient(); }}
                  onChange={handleChange}
                  placeholder="Search patient by name/contact number"
                />
              </div> 
              
             
            
            {/* </div> */}
            <Avatar sx={{ bgcolor: '#2c6bd7', cursor: 'pointer' }} onClick={handleMenuOpen}>
              {FirstN + LastN}
            </Avatar>
            <Menu  anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <Tooltip title="Logout" placement="right">
              <MenuItem onClick={HandleLogout}>
                 <img src={logout} alt="user" width="24" height="24" style={{filter: 'invert(1)'}} />
              </MenuItem>
              </Tooltip>
            </Menu>
          </AppBar>
            {
              PatientData.length > 0 && (
                <table ref={tblRef}   className='search-tbl table  table-striped'>
                  <thead>
                    <tr className='common-FontSize-small common-FontFamily common-FontWeight'>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Age</th>
                    </tr>
                  </thead>
                   <tbody>
                   {PatientData.map((item, index) => (
                     <tr className='common-FontSize-small common-FontFamily common-FontWeight'
                     key={index} onClick={() => NavigatetoProfile(item.patientID) }>
                      <td>{item.patientName}</td>
                      <td>{item.contactNumber}</td>
                      <td>{item.age}</td>

                    </tr>
                  ))}
                   </tbody>
                </table>
              
              )
            }
            
       </Box>
      </Box>
    </>
  );
};

export default Layout;
