import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { toast } from "react-toastify";
import { MonthlyDailyReport } from "../../Services/Dashboard";
import SpinnerModal from "../../Spinner/SpinnerModal";
import * as XLSX from "xlsx";

const MonthlyReports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [monthlyReports, setMonthlyReports] = useState([]);

  // Fetch Monthly Reports
  const GetMonthlyDaily = async () => {
    try {
      setIsLoading(true);
      const LabID = sessionStorage.getItem("LabID");
      const response = await MonthlyDailyReport(LabID);
      setMonthlyReports(response.monthlyReports);
    } catch (error) {
      toast.error("Unable to get data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetMonthlyDaily();
  }, []);
//-------------------------------------------data table===============
  // Define columns for the data table
  const columns = [
    {
      name: "Month",
      selector: (row) => row.monthName,
      cellExport: (row) => row.monthName,
      sortable: true,
    },
    {
      name: "Tests",
      selector: (row) => row.totalTest,
      cellExport: (row) => row.totalTest,
      sortable: true,
    },
    {
      name: "Sell",
      selector: (row) => row.totalGrandTotal,
      cellExport: (row) => row.totalGrandTotal,
      sortable: true,
    },
    {
      name: "Discount",
      selector: (row) => row.totalDiscount,
       cellExport: (row) => row.totalDiscount,
      sortable: true,
    },
    {
      name: "Received Amount",
      selector: (row) => row.receivedAmount,
      cellExport: (row) => row.receivedAmount,
      sortable: true,
    },
    {
      name: "Due Amount",
      selector: (row) => row.dueAmount,
      cellExport: (row) => row.dueAmount,
      sortable: true,
    },
  ];

  // Table Data with Extensions
  const tableData = {
    columns,
    data: monthlyReports,
  };
  const customStyles = {
    rows: {
      style: {
        borderBottom: '1px solid #ccc', // Row border
      },
    },
    cells: {
      style: {
        borderRight: '1px solid #ccc', // Column border
      },
    },
    headCells: {
      style: {
        borderTop:'1px solid #ccc',
        borderRight: '1px solid #ccc', // Column border in header
        backgroundColor: 'white',
      },
    },
  };
  return (
    <>
      {isLoading && <SpinnerModal isLoading={isLoading} />}
      <div className="reports-container">
        
        <DataTableExtensions {...tableData}>
          <DataTable
            pagination
            fixedHeader
            fixedHeaderScrollHeight="80vh"
            highlightOnHover
            pointerOnHover
            responsive
            striped
            customStyles={customStyles}
          />
        </DataTableExtensions>
      </div>
    </>
  );
};

export default MonthlyReports;
